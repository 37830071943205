@import "./colors.scss";

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
    opacity: 0.2;
}

.table-title {
    font-size: 13px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.pagination {
    color: #1A1B25;
    width: 100%;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 14px;

    p {
        margin: 0px;
    }

    div {
        display: flex;
        align-items: center;
    }

    .pagination-buttons {
        button {
            border: 1px solid #c9c9c9;
            background-color: #fff;
            border-radius: 6px;
            padding: 6px 10px;
            cursor: pointer;
            letter-spacing: 1px;
            color: #1A1B25;
        }

        button:disabled {
            color: #778;
            border: 1px solid #c9c9c9;
            background-color: #fff;
            cursor: default;
        }
    }
}
