.labnotes{
    .block1{
        padding-left: 80px;
        padding-right: 80px;
        position: relative;
        z-index: 2;

        .image1-container{
            width: 100%;
            height: 808px;

            .image1{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        
        .absolute-container{
            position: absolute;
            top: 148px;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: inherit;
            padding-right: inherit;

            .header-text{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                margin-bottom: 144px;

                .header-logo{
                    max-width: 180px;
                    object-fit: cover;
                }
        
                h1{
                    font-family: EncodeSansSemiBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 64px;
                    line-height: 80px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-bottom: 15px;
                    margin-top: 0px;
                    max-width: 754px;
                }
        
                h3{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-bottom: 15px;
                    margin-top: 0px;
                    max-width: 747px;
                }
        
                .header-button{
                    padding: 15px 5px;
                    width: 215px;
                    height: 53px;
                    background: #FFFFFF;
                    border-radius: unset;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #0000FF;
                    text-transform: initial;
                }
            }

            .absolute-blocks{
                max-width: 760px;
                width: 760px;
                position: relative;
    
                img{
                    position: absolute;
                    left: 0;
                    top: -35px;
                    width: 100%;
                    right: 0;
                }
            }

        }
    }

    .block2{
        padding-top: 470px;
        padding-bottom: 150px;

        .block2-flex-container{
            max-width: 945px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: space-between;
            margin: auto;

            .block2-flex-div:first-child{
                p{
                    font-weight: 700;
                    font-family: EncodeSansBold;
                }
            }
            .block2-flex-div{
                width: 50%;
                max-width: 465px;

                p{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #000000;
                    margin: 0px;
                }
            }
        }
    }

    .block3{
        padding-top: 150px;
        padding-bottom: 150px;
        background-color: #F4F4F4;

        .block3-flex-container{
            max-width: 1137px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: stretch;
            margin: auto;
            justify-content: space-between;
            
            .block3-flex-divs{
                background-color: #FFFFFF;
                padding: 50px 30px;
                border-radius: 20px;
                max-width: 369px;
                height: 240px;
                margin-bottom: 15px;

                img{
                    object-fit: cover;
                    height: 35px;
                }

                p{
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 30px;
                    color: #000000;
                    max-width: 309px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .block4{
        padding-left: 80px;
        padding-right: 80px;
        background-color: #ffffff;
        padding-top: 150px;

        .block4-content{
            .block4-text-bottom{
                padding-bottom: 150px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;

                h4{
                    max-width: 865px;
                    width: 100%;
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 50px;
                    text-align: center;
                    color: #000000;
                }

                .solicitar-button{
                    width: 215px;
                    height: 53px;
                    background: #0000FF;
                    border-radius: unset;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    text-transform: initial;
                }
            }

            .divider{
                flex-shrink: 0;
                border-width: 0;
                border-style: solid;
                border-color: #C4C4C4;
                border-bottom-width: 2px;
                max-width: 945px;
                margin: auto;
            }
        }
    }
}