@media(max-width: 991px){
    .demo-request{
        .block1{
            padding-left: 29px;
            padding-right: 29px;
        }
    }
}

@media(max-width: 767px){
    .demo-request{
        .block1{
            padding-left: 23px;
            padding-right: 23px;
        }
    }
}
