.support{
    .block1{
        padding-top: 150px;
        padding-bottom: 75px;
        padding-left: 29px;
        padding-right: 29px;

        .block1-container{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: nowrap;
            max-width: 1137px;
            margin: auto;

            .block1-title{
                margin-right: 30px;

                h1{
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 64px;
                    line-height: 80px;
                    color: #000000;
                    margin: 0px;
                    width: max-content;
                }
            }
            .block1-text{
                max-width: 657px;
                
                p{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #666666;
                    margin-top: 0px;
                }
                .block1-client-access{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;

                    img{
                        width: 13.75px;
                        object-fit: cover;
                        margin-right: 10px;
                    }

                    p{
                        font-family: EncodeSansBold;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: #000000;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    .block2{
        padding-top: 75px;
        padding-bottom: 40px;

        .block2-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            max-width: 1152px;
            margin: auto;

            .block2-flex-div{
                height: 399px;
                width: 273px;
                background-color: #F4F4F4;
                border-radius: 20px;
                overflow: hidden;
                margin-left: 7.5px;
                margin-right: 7.5px;
                margin-bottom: 22px;
                
                .flex-img{
                    height: 181px;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .block2-flex-content{
                    padding: 20px;
                    height: 178px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: stretch;
                    justify-content: center;

                    .flex-text{
    
                        h3{
                            margin-top: 0px;
                            margin-bottom: 15px;
                            font-family: EncodeSansSemiBold;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 28px;
                            color: #000000;
                        }
                        p{
                            margin: 0px;
                            font-family: EncodeSansRegular;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            color: #000000;
                        }
                    }
                    .flex-access{
                        margin-top: 15px;
                        display: flex;
                        justify-content: flex-end;
    
                        p{
                            margin: 0px;
                            font-family: EncodeSansRegular;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: right;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }

    .block3{
        .block3-text-bottom{
            padding-top: 40px;
            padding-bottom: 80px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            h4{
                max-width: 910px;
                width: 100%;
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                color: #000000;
                margin-top: 0px;
            }

            .solicitar-button{
                width: 215px;
                height: 53px;
                background: #0000FF;
                border-radius: unset;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                text-transform: initial;
            }
        }
    }
}