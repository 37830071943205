.cookies-policy{
    max-width: 740px;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 100px;

    h1{
        font-size: 48px;
        font-family: EncodeSansRegular;
        font-weight: 500;
        color: #616161;
    }

    .MuiTableContainer-root, p, ul{
        margin-bottom: 1em;
        margin-top: 1em;
    }

    p, td, th, li{
        font-size: 16px !important;
        color: #616161;
    }

    p{
        font-family: EncodeSansLight;
        font-weight: 300;
    }

    td, li{
        font-family: EncodeSansRegular;
        font-weight: 400;
    }

    .text-bold, th{
        font-family: EncodeSansBold;
        font-weight: 700;
    }
}