.support-content{
    .block1{
        padding-top: 150px;
        padding-bottom: 75px;
        padding-left: 29px;
        padding-right: 29px;

        .block1-container{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: nowrap;
            max-width: 1137px;
            margin: auto;

            .block1-title{
                margin-right: 15px;
                max-width: 465px;

                h1{
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 64px;
                    line-height: 80px;
                    color: #000000;
                    margin: 0px;
                    width: max-content;
                }

                h2{
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 80px;
                    color: #000000;
                    margin: 0px;
                    width: max-content;
                }
            }
            .block1-text{
                max-width: 657px;
                
                p{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #666666;
                    margin-top: 0px;
                }
            }
        }
    }

    .support-type{
        padding: 75px 0px;
    }

    .support-type-gray{
        background-color: #F4F4F4;
    }

    .support-dinamic-content{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        max-width: 1139px;
        margin: auto;
        padding-left: 29px;
        padding-right: 29px;

        .block2{
            .active{
                color: #0000FF !important;
            }

            .block2-container{
                .MuiButtonBase-root, .MuiAccordionDetails-root{
                    padding: 0px;
                }

                .MuiPaper-root{
                    border: none;
                    border-radius: 0px;
                    box-shadow: none;
                }

                .MuiAccordionSummary-expandIconWrapper{
                    display: none !important;
                }

                .MuiAccordionSummary-content{
                    margin-bottom: 10px !important;
                }

                .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
                    display: none !important;
                }

                .first-list{
                    list-style-type: none;
                    padding: 0px;
                    margin: 0px;
                    background-color: transparent;
    
                    p{
                        font-family: EncodeSansRegular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 150%;
                        color: #000000;
                    }
    
                    .second-list{
                        list-style-type: none;
                        padding-left: 20px;
                        padding-top: 0px;
                        padding-bottom: 10px;
                        margin: 0px;
    
                        li{
                            font-family: EncodeSansRegular;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 150%;
                            color: #000000;
                            cursor: pointer;
                        }

                        li:hover{
                            color: #0000FF;
                        }
                    }
                }
            }
        }

        .support-separator{
            width: 2px;
            background-color: #C4C4C4;
            margin-left: 10px;
            margin-right: 10px;
        }
    
        .block3{
            max-width: 753px;
            width: 753px;

            img{
                max-width: 100%;
            }
    
            .bibliografia-container{
                margin-bottom: 30px;
    
                .bibliografia-content{
                    padding-bottom: 30px;

                    h5{
                        font-family: EncodeSansRegular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 150%;
                        color: #0000FF;
                        margin: 0px;
                    }
                    p{
                        font-family: EncodeSansRegular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                        color: #000000;
                        margin: 0px;
                    }
                }
            }
    
            .tutoriales-container{
                .tutoriales-content{
                    .tutoriales-bloques{
                        background-color: #F4F4F4;
                        margin-bottom: 10px;
                        padding-left: 30px;
                        padding-right: 30px;
                        padding-bottom: 15px;
                        padding-top: 15px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: space-between;

                        .tutoriales-title{
                            max-width: 565px;

                            h5{
                                font-family: EncodeSansSemiBold;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 20px;
                                color: #000000;
                                margin-bottom: 5px;
                                margin-top: 0px;
                            }
                            p{
                                font-family: EncodeSansRegular;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #666666;
                                margin: 0px;
                            }
                        }
                        .tutoriales-link{
                            img{
                                width: 24px;
                                object-fit: cover;
                            }
                        }
                    }
                    .tutoriales-text{
                        font-family: EncodeSansRegular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #666666;
                        margin: 0px;
                    }
                }
            }
    
            .videos-container{
                .videos-content{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 50px;

                    .videos-title{
                        max-width: 273px;
                        
                        h5{
                            font-family: EncodeSansBold;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 22px;
                            line-height: 28px;
                            color: #000000;
                            margin: 0px;
                        }
                        p{
                            font-family: EncodeSansRegular;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;
                            color: #000000;
                            margin: 10px 0px 0px 0px;
                        }
                    }
                    .videos-video{
                        .videos-video-item{
                            margin-bottom: 30px;

                            .item-video{
                                width: 465px;
                                height: 262px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
    
            .descargas-container{
                .descargas-content{
                    background-color: #FFFFFF;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    padding: 15px 30px;

                    .descargas-img{
                        width: 40px;
                        height: 40px;
                        margin-right: 30px;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                        }
                    }
                    .descargas-text{
                        max-width: 643px;
                        width: 100%;

                        h5{
                            font-family: EncodeSansSemiBold;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 20px;
                            color: #000000;
                            margin-bottom: 5px;
                            margin-top: 0px;
                        }

                        p{
                            font-family: EncodeSansRegular;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #666666;
                            margin-bottom: 0px;
                            margin-top: 0px;
                        }
                    }
                    .descargas-link{
                        min-width: 40px;
                        max-width: 40px;

                        img{
                            width: 24px;
                            height: 28px;
                        }
                    }
                }
            }

            .links-container{
                margin-bottom: 30px;
    
                .links-content{
                    padding-bottom: 30px;

                    a{
                        font-family: EncodeSansRegular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                        color: #0000FF;
                        margin: 0px;
                    }
                    a:visited{
                        color: #0000FF;
                    }
                    p{
                        font-family: EncodeSansRegular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                        color: #000000;
                        margin: 5px 0px 0px 0px;
                    }
                }
            }
    
            .faq-container{
                .faq-content{
                    background-color: #F4F4F4;
                    margin-bottom: 10px;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    border-radius: 0px;
                    box-shadow: none;

                    .MuiButtonBase-root{
                        padding: 0px;
                        min-height: auto;

                        .MuiAccordionSummary-content{
                            margin: 0px;

                            .faq-title{
                                font-family: EncodeSansSemiBold;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 20px;
                                color: #000000;
                                margin: 0px;
                            }
                        }
                        .MuiAccordionSummary-expandIconWrapper{
                            svg{
                                transform: scale(1.667);
                                fill: #000000;
                            }
                        }
                    }

                    .MuiCollapse-root{
                        .MuiCollapse-wrapper{
                            margin-top: 10px;
                            
                            .MuiAccordion-region{
                                .MuiAccordionDetails-root{
                                    padding: 0px;

                                    .faq-text{
                                        font-family: EncodeSansRegular;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 18px;
                                        color: #000000;
                                        margin: 0px;
                                        letter-spacing: inherit;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    .block4{
        .block4-text-bottom{
            padding-top: 80px;
            padding-bottom: 80px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            h4{
                max-width: 910px;
                width: 100%;
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                color: #000000;
                margin-top: 0px;
            }

            .solicitar-button{
                width: 215px;
                height: 53px;
                background: #0000FF;
                border-radius: unset;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                text-transform: initial;
            }
        }
    }
}
