@media(max-width: 991px){
    .about-us{
        padding-left: 0px;
        padding-right: 0px;

        .block1{
            .image1-container{
                height: 590px;
            }
            
            .absolute-container{
                top: 134px;
    
                .header-text{
                    margin-bottom: 50px;
            
                    h1{
                        font-size: 48px;
                        line-height: 60px;
                    }
                }
    
                .absolute-blocks{
                    .appert-flow-container{
                        .appert-flow-content{
                            .appert-flow-isotipo-container{
                                .appert-flow-isotipo{
                                    object-fit: contain;
                                }
                            }
    
                            .appert-flow-text{
                                width: 16.7em;
                            }
                        }
                    }
        
                    .labnotes-isotipo-container{
                        .labnotes-isotipo-content{
                            .labnotes-isotipo-img-container{
                                .labnotes-isotipo{
                                    object-fit: contain;
                                }
                            }
    
                            .labnotes-isotipo-text{
                                width: 16.7em;
                            }
                        }
                    }
                }
            }

            .block1-text-bottom{
                padding-bottom: 75px;
                padding-left: 29px;
                padding-right: 29px;
            }
        }
    
        .block2{
            padding-bottom: 100px;
            padding-left: 29px;
            padding-right: 29px;

            .block2-flex-container{
    
                .block2-flex-div{
    
                    p{
                        font-size: 18px;
                    }
                }
            }
        }
    
        .block3{
            padding-left: 29px;
            padding-right: 29px;

            .block3-flex-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;
    
                .block3-flex-div{
                    max-width: none;
                    width: 100%;

                    img{
                        position: inherit;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        margin: auto;
                        width: 100%;
                    }
    
                    p{
                        font-size: 18px;

                        span{
    
                        }
                    }
                }
            }
        }
    
        .block4{
            padding-left: 29px;
            padding-right: 29px;

            .block4-flex-container{
                .block4-flex-div{
                    img{
                        
                    }
                    p{
                        font-size: 18px;

                        span{
                            
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 767px){
    .about-us{
        padding-left: 0px;
        padding-right: 0px;

        .block1{
            padding-left: 0px;
            padding-right: 0px;

            .image1-container{
                position: absolute;
                z-index: -1;
                height: 70%;
            }
            
            .absolute-container{
                position: unset;
                padding-top: 45px;
                padding-left: 23px;
                padding-right: 23px;
                padding-bottom: 50px;
    
                .header-text{
                    margin-bottom: 100px;
    
                    .header-logo{
                        display: none;
                    }
            
                    h1{
                        font-size: 32px;
                        line-height: 40px;
                        margin-bottom: 30px;
                    }
                }
    
            }
        }
    
        .block2{
            padding-top: 0px;
            padding-bottom: 50px;
            padding-left: 23px;
            padding-right: 23px;
    
            .block2-flex-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;

                .block2-flex-div{
                    width: 100%;
                    max-width: none;

                    p{
                        margin-bottom: 15px;
                    }
                }
            }
        }
    
        .block3{
            padding-top: 50px;
            padding-bottom: 100px;
            padding-left: 23px;
            padding-right: 23px;

            .block3-flex-container{
    
                .block3-flex-div{
                    img{
    
                    }
    
                    p{
    
                        span{
    
                        }
                    }
                }
            }
        }
    
        .block4{
            padding-bottom: 55px;
            padding-left: 23px;
            padding-right: 23px;

            .block4-flex-container{
                align-items: stretch;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;

                .block4-flex-div{
                    max-width: none;
                    width: 100%;
                    margin-bottom: 45px;

                    img{
                        
                    }
                    p{
                        span{
                            
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 575px){
    .about-us{
        .block1{
            .image1-container{
                height: 295px !important;
            }
        }

        .block2{
            padding-top: 50px;
        }
    }
}