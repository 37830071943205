html, body{
    font-family: EncodeSansRegular;
}

body{
    margin: 0px;
}

a{
    text-decoration: none;
}

.display-none{
    display: none !important;
}

button{
    transition: all 0.2s;
}

button:hover{
    background-image: linear-gradient(rgb(0 0 0/10%) 0 0) !important;
}

/* Header */
.header{
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 80px;

    .logo-container{
        height: inherit;

        img{
            height: 100%;
            width: auto;
        }
    }
    .logo-container, .menu{
        width: max-content;
    }
    .menu{
        .menu-desktop{
            display: block;
            height: inherit;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
    
            .clientes-container{
                margin-bottom: 11px;
                
                p{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: right;
                    color: #000000;
                }
            }
    
            .menu-links{

                .menu-list{
                    ul{
                        background: rgb(250, 250, 250);
                        border: 1px solid rgb(230, 230, 230);
                        box-sizing: border-box;
                        box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
                        border-radius: 5px;
                        padding: 5px 0px;
                        
                        li{
                            padding: 5px 10px;
                        }
                    }
                }
            }

            .menu-links p{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                min-width: auto;
                margin-left: 50px;
            }
    
            .menu-links .menu-solicitar-demo{
                color: #000AFF !important;
            }

            .logo-gemina{
                a{
                    height: 38px;
                    display: block;

                    img{
                        height:100%;
                    }
                }
            }

            .logo-gemina-componentes{
                a{
                    height: 63px;
                    display: block;
                    padding-bottom: 9px;
                    img{
                        height: 100%;
                    }
                }
            }
    
            .header-icon-container{
                display: flex;
                align-items: center;
            }
    
            .header-icon{
                width: 33px;
            }
        }

        .menu-mobile{
            display: none !important;

            .header-icon-container{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-start;
                justify-content: flex-end;
                padding: 0px;

                svg{
                    fill: #000000;
                }
            }

            .mobile-menu-list{
                z-index: 100;

                .menu-solicitar-demo{
                    color: #000AFF !important;
                }

                .logo-gemina{
                    a{
                        height: 40px;
                        display: block;
    
                        img{
                            height: 100%;
                        }
                    }
                }

                .logo-gemina-componentes{
                    a{
                        height: 46px;
                        display: block;
    
                        img{
                            height: 100%;
                        }
                    }
                }

                #composition-menu{

                    .menu-list{
                        ul{
                            background: rgb(250, 250, 250);
                            border: 1px solid rgb(230, 230, 230);
                            box-sizing: border-box;
                            box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
                            border-radius: 5px;
                            padding: 5px 0px;
                            
                            li{
                                padding: 5px 10px;
                            }
                        }
                    }
                }

                #composition-menu > div{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: center;
                    padding: 10px;

                    p, button{
                        text-align: left;
                        padding: 5px 10px;
                        width: 100%;
                    }

                    button{
                        margin-left: -20px;
                    }
                }
            }
        }
        
    } 
}
/* Fin Header */

/* Login */
.login-form, .recover-password-form{
    min-height: calc(100vh - 473px);
    display: flex !important;
    justify-content: center;
    align-items: center;
}
/* Fin Login */

/* Footer */
.footer{
    background: #000000;

    .footer-content{
        max-width: 1136px;
        margin: auto;
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;

        .footer-logo-container{
            width: 51px;
            height: 51px;
            border: 2px solid #FFFFFF;
    
            img{
                width: 100%;
                height: 100%;
            }
        }
    
        .footer-lists-container{
            margin-top: 30px;
            margin-bottom: 84px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;

            ul, a{
                padding: 0px;
            }

            ul{
                margin-right: 10.1vw;
            }

            a{
                width: max-content;
            }

            .para-estudiantes{
                display: block;
            }

            .para-estudiantes-salto{
                display: none;
                margin-bottom: 0px !important;
                margin-top: 0px !important;
            }

            .first-list-item{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;            
            }
    
            .footer-list-item{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #808080;            
            }

            .footer-list-item:hover{
                text-decoration: none;
            }
        }
    
        .copyright-container{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #808080;
        }
    }
}
/* Fin Footer */

/* Modal de descarga */
.download-modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #0000007d;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;

    .download-modal-text-container{
        background-color: white;
        width: calc(97% - 40px);
        max-width: 500px;
        height: max-content;
        padding: 25px 20px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        p{
            font-size: 16px;
            line-height: 22px;
            
            span{
                font-family: EncodeSansBold;
                font-weight: 700;
            }
        }

        button{
            background-color: #0000FF;
            color: #ffffff;
            border: none;
            padding: 10px 15px;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
/* Fin modal de descarga */