@font-face {
    font-family: EncodeSansBlack;
    src: url(../assets/Fonts/EncodeSans-Black.ttf) format('truetype');
}

@font-face {
    font-family: EncodeSansBold;
    src: url(../assets/Fonts/EncodeSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: EncodeSansExtraBold;
    src: url(../assets/Fonts/EncodeSans-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: EncodeSansExtraLight;
    src: url(../assets/Fonts/EncodeSans-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: EncodeSansLight;
    src: url(../assets/Fonts/EncodeSans-Light.ttf) format('truetype');
}

@font-face {
    font-family: EncodeSansMedium;
    src: url(../assets/Fonts/EncodeSans-Medium.ttf) format('truetype');
}

@font-face {
    font-family: EncodeSansRegular;
    src: url(../assets/Fonts/EncodeSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: EncodeSansSemiBold;
    src: url(../assets/Fonts/EncodeSans-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: EncodeSansThin;
    src: url(../assets/Fonts/EncodeSans-Thin.ttf) format('truetype');
}