/* Header */
@media(max-width: 991px){
    .header{
        .menu{
            .menu-desktop{
                display: none !important;
            }

            .menu-mobile{
                display: block !important;
            }
        }
    }
}

@media(max-width: 767px){
    .header{
        padding-right: 0px;

        .menu{
            .menu-mobile{
                display: block !important;

                .header-icon-container{
                    padding-right: 15px;
                }
            }
        }
    }
}

@media(max-width: 575px){
    .header{
        height: 40px;
    }
}
/* Fin Header */

/* Login */
@media(max-width: 767px){
    .login-form, .recover-password-form{
        min-height: calc(100vh - 40px);
    }
}
/* Fin Login */

/* Footer */
@media(max-width: 991px){
    .footer{
        .footer-content{
            padding-left: 29px;
            padding-right: 29px;
        }
    }
}

@media(min-width: 768px) and (max-width: 991px){
    .header{
        padding-right: 30px;
    }
}

@media(max-width: 767px){
    .footer{
        .footer-content{
            padding-bottom: 50px;

            .footer-lists-container > div{
                margin-bottom: 20px;
            }

            .footer-lists-container{
                flex-wrap: wrap;

                ul{
                    margin-right: 30px;
                }
            }
        }
    }
}

@media(max-width: 430px){
    .footer{
        .footer-content{
            padding-bottom: 50px;

            .footer-lists-container > div{
                margin-bottom: 20px;
            }

            .footer-lists-container{
                flex-wrap: wrap;

                ul{
                    margin-right: 28px;
                }

                .bloque-estudiantes{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: 4px !important;
                    margin-bottom: 4px !important;

                    .para-estudiantes{
                        display: none !important;
                    }
    
                    .para-estudiantes-salto{
                        display: block !important;
                    }
                }
            }
        }
    }
}
/* Fin Footer */