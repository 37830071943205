@media(max-width: 1160px){
    .block1{
        padding-bottom: 0px;
        
        .absolute-container{
            padding-bottom: 0px !important;

            .absolute-blocks{
                width: 100% !important;
    
                img{
                    position: initial !important;
                    left: auto !important;
                    top: auto !important;
                    width: 100% !important;
                }
            }
        }
    }
}

@media(max-width: 1115px){
    .block3{
        .block3-flex-container{
            .block3-flex-divs{
                width: 25%;
                height: auto !important;
            }
        }
    }
}

@media(max-width: 991px){
    .labnotes{
        .block1{
            .image1-container{
                height: 590px;
            }
            
            .absolute-container{
                top: 134px;
    
                .header-text{
                    margin-bottom: 50px;
            
                    h1{
                        font-size: 48px;
                        line-height: 60px;
                    }
            
                    h3{
                        font-size: 24px;
                        line-height: 30px;
                        width: 65%;
                    }
                }
    
                .absolute-blocks{
                    
                    .appert-flow-container{
        
                        .appert-flow-content{
        
                            .appert-flow-isotipo-container{
            
                                .appert-flow-isotipo{
                                    object-fit: contain;
                                }
                            }
    
                            .appert-flow-text{
                                width: 16.7em;
                            }
            
                        }
        
                    }
        
                    .labnotes-isotipo-container{
        
                        .labnotes-isotipo-content{
        
                            .labnotes-isotipo-img-container{
            
                                .labnotes-isotipo{
                                    object-fit: contain;
                                }
                            }
    
                            .labnotes-isotipo-text{
                                width: 16.7em;
                            }
            
                        }
        
                    }
                }
    
            }
        }

        .block2{
            padding-bottom: 100px;
            padding-left: 29px;
            padding-right: 29px;

            .block2-flex-container{
    
                .block2-flex-div:first-child{
                    margin-right: 10px;
                }

                .block2-flex-div{
                    
                    p{
                        font-size: 18px;
                    }
                }
            }
        }

        .block3{
            padding-left: 29px;
            padding-right: 29px;

            .block3-flex-container{
                .block3-flex-divs{
                    p{
                        font-size: 18px;
                    }
                }
            }
        }

        .block4{
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 75px;
            background-color: #ffffff;

            .block4-content{
    
                .block4-images-container{
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 100%;

                    .block4-images-block{
                        max-height: 600px !important;
                    }
                }

                .block4-text-bottom{
                    h4{
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}

@media(min-width: 768px) and (max-width: 991px){
    .appertflow{
        .block1{
            
            .absolute-container{

                .absolute-blocks{
                    max-width: 620px;
                }

            }
        }
    }
}

@media(max-width: 767px){
    .labnotes{
        .block1{
            padding-left: 0px;
            padding-right: 0px;
            /*background: #000000;*/

            .image1-container{
                position: absolute;
                z-index: -1;
                height: 47%;
            }
            
            .absolute-container{
                position: unset;
                padding-top: 137px;
                padding-left: 23px;
                padding-right: 23px;
                padding-bottom: 100px;
    
                .header-text{
                    margin-bottom: 143px;
    
                    .header-logo{
                        display: none;
                    }
            
                    h1{
                        font-size: 32px;
                        line-height: 40px;
                        margin-bottom: 30px;
                    }
            
                    h3{
                        font-size: 18px;
                        line-height: 22px;
                        width: 75%;
                        margin-bottom: 30px;
                    }
                }
    
                .absolute-blocks{
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                }
    
            }
        }

        .block2{
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 23px;
            padding-right: 23px;
    
            .block2-flex-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;

                .block2-flex-div{
                    width: 100%;
                    max-width: none;

                    p{
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .block3{
            padding-top: 50px;
            padding-left: 23px;
            padding-right: 23px;

            .block3-flex-container{
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: nowrap;

                .block3-flex-divs{
                    max-width: 369px;
                    width: auto;
                }
            }
        }

        .block4{
            padding-left: 23px;
            padding-right: 23px;
            padding-top: 50px;
            padding-bottom: 50px;
    
            .block4-content{
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                
                .block4-text-bottom{
                    margin-right: 0px;
                    padding-left: 0px;
                    max-width: 100%;
                    margin-bottom: 32px;
                    padding-bottom: 0px;
    
                    h4{
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
    
                .block4-image-container{
                    margin-right: 0px;
                    width: 100%;
                    border-radius: 20px;
                    height: auto;
                    
                    img{
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

@media(max-width: 575px){
    .labnotes{
        .block1{
            .image1-container{
                height: 100% !important;
            }
            .absolute-container{
                padding-bottom: 50px;

                .header-text{
                    margin-bottom: 100px;
                }

                img{
                    object-position: center !important;
                }
            }
        }

        .block4{
            .block4-image-container{
                height: 236px !important;
            }
        }
    }
}