.about-us{
    .block1{
        padding-left: 80px;
        padding-right: 80px;
        position: relative;
        z-index: 2;

        .image1-container{
            width: 100%;
            height: 808px;
            position: relative;

            .block1-images-filter{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                background-image: linear-gradient(#ffffff, #ffffff00);
            }

            .image1{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        
        .absolute-container{
            position: absolute;
            top: 148px;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: inherit;
            padding-right: inherit;

            .header-text{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                margin-bottom: 144px;
        
                h1{
                    font-family: EncodeSansMedium;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 64px;
                    line-height: 80px;
                    text-align: center;
                    color: #000000;
                    max-width: 676px;
                }
            }

        }

        .block1-text-bottom{
            padding-top: 100px;
            padding-bottom: 150px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            h4{
                max-width: 910px;
                width: 100%;
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                color: #000000;
                margin-top: 0px;
            }

            .solicitar-button{
                width: 215px;
                height: 53px;
                background: #0000FF;
                border-radius: unset;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                text-transform: initial;
            }
        }
    }

    .block2{
        padding-top: 100px;
        padding-bottom: 150px;

        .block2-flex-container{
            max-width: 945px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: space-between;
            margin: auto;

            .block2-flex-div:first-child{
                p{
                    font-weight: 700;
                    font-family: EncodeSansBold;
                }
            }
            .block2-flex-div{
                width: 50%;
                max-width: 465px;

                p{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #000000;
                    margin: 0px;
                }
            }
        }
    }

    .block3{
        padding-bottom: 150px;

        .block3-flex-container{
            max-width: 945px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            margin: auto;

            .block3-flex-div{
                width: 50%;
                max-width: 465px;
                position: relative;

                img{
                    width: 100%;
                    object-fit: cover;
                }

                p{
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 38px;
                    color: #000000;
                    margin: 0px;

                    span{
                        color: #000AFF;
                    }
                }
            }
        }
    }

    .block4{
        padding-bottom: 150px;

        .block4-divider{
            width: 100%;
            max-width: 1137px;
            height: 0px;
            border: 1px solid #000000;
            margin: auto;
            margin-bottom: 45px;
        }
        
        .block4-flex-container{
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            max-width: 1137px;
            margin: auto;

            .block4-flex-div{
                max-width: 369px;
                width: 33%;
                
                div{
                    max-width: 369px;
                    max-height: 177px;
                    overflow: hidden;
                    
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                p{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #000000;

                    span{
                        font-family: EncodeSansBold;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}