.distributor{
    .block1{
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 150px;
        padding-bottom: 150px;
        background-color: #F4F4F4;
        
        .absolute-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: inherit;
            padding-right: inherit;

            .header-text{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                margin-bottom: 50px;
        
                h1{
                    font-family: EncodeSansMedium;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 64px;
                    line-height: 80px;
                    text-align: center;
                    color: #000000;
                    max-width: 499px;
                    margin-top: 0px;
                    margin-bottom: 15px;
                }

                p{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #666666;
                    max-width: 942px;
                    margin-top: 0px;
                    margin-bottom: 0px;

                    span{
                        font-weight: bold;
                        color: #000000;
                        font-family: EncodeSansBold;
                    }
                }
            }

            .distributor-container{
                max-width: 1152px;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(271px, 1fr));
                grid-auto-rows: 1fr;
                grid-column-gap: 15px;
                grid-row-gap: 30px;
                justify-items: center;

                .distributor-content{
                    width: 213px;
                    background: #FFFFFF;
                    padding: 50px 30px;
                    // margin-left: 7.5px;
                    // margin-right: 7.5px;
                    // margin-bottom: 30px;

                    h5{
                        font-family: EncodeSansSemiBold;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 30px;
                        color: #000000;
                        margin-bottom: 10px;
                        margin-top: 0px;
                    }

                    p{
                        font-family: EncodeSansRegular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        color: #666666;
                        margin: 0px;
                    }
                }
            }

            

        }

        .block1-text-bottom{
            padding-top: 100px;
            padding-bottom: 150px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            h4{
                max-width: 910px;
                width: 100%;
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                color: #000000;
                margin-top: 0px;
            }

            .solicitar-button{
                width: 215px;
                height: 53px;
                background: #0000FF;
                border-radius: unset;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                text-transform: initial;
            }
        }

        .lds-roller {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }
        .lds-roller div {
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 40px 40px;
        }
        .lds-roller div:after {
        content: " ";
            display: block;
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: rgb(115, 115, 115);
            margin: -4px 0 0 -4px;
        }
        .lds-roller div:nth-child(1) {
            animation-delay: -0.036s;
        }
        .lds-roller div:nth-child(1):after {
            top: 63px;
            left: 63px;
        }
        .lds-roller div:nth-child(2) {
            animation-delay: -0.072s;
        }
        .lds-roller div:nth-child(2):after {
            top: 68px;
            left: 56px;
        }
        .lds-roller div:nth-child(3) {
            animation-delay: -0.108s;
        }
        .lds-roller div:nth-child(3):after {
            top: 71px;
            left: 48px;
        }
        .lds-roller div:nth-child(4) {
            animation-delay: -0.144s;
        }
        .lds-roller div:nth-child(4):after {
            top: 72px;
            left: 40px;
        }
        .lds-roller div:nth-child(5) {
            animation-delay: -0.18s;
        }
        .lds-roller div:nth-child(5):after {
            top: 71px;
            left: 32px;
        }
        .lds-roller div:nth-child(6) {
            animation-delay: -0.216s;
        }
        .lds-roller div:nth-child(6):after {
            top: 68px;
            left: 24px;
        }
        .lds-roller div:nth-child(7) {
            animation-delay: -0.252s;
        }
        .lds-roller div:nth-child(7):after {
            top: 63px;
            left: 17px;
        }
        .lds-roller div:nth-child(8) {
            animation-delay: -0.288s;
        }
        .lds-roller div:nth-child(8):after {
            top: 56px;
            left: 12px;
        }
        @keyframes lds-roller {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .block2{
        padding-left: 80px;
        padding-right: 80px;
        background-color: #ffffff;
        padding-top: 150px;

        .block2-content{
            
            .block2-image-text-container{
                max-width: 1137px;
                background-color: #F4F4F4;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: stretch;
                margin: auto;
                border-radius: 25px;

                .block2-image-container{
                    width: 50%;
                    border-radius: 25px 0px 0px 25px;
                    overflow: hidden;
                    max-width: 561px;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .block2-text-container{
                    width: 39%;
                    padding-left: 9.3%;
                    padding-right: 2.5%;
                    padding-top: 2.5%;
                    padding-bottom: 2.5%;

                    h3{
                        font-family: EncodeSansBold;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: #000000;
                    }

                    p{
                        font-family: EncodeSansRegular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        color: #000000;
                        max-width: 441px;
                    }
                }
            }

            .block2-text-bottom{
                padding-bottom: 150px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;

                h4{
                    max-width: 850px;
                    width: 100%;
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 50px;
                    text-align: center;
                    color: #000000;
                }

                .solicitar-button{
                    width: 215px;
                    height: 53px;
                    background: #0000FF;
                    border-radius: unset;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    text-transform: initial;
                }
            }

            .divider{
                flex-shrink: 0;
                border-width: 0;
                border-style: solid;
                border-color: #C4C4C4;
                border-bottom-width: 2px;
                max-width: 945px;
                margin: auto;
            }
        }
    }

    .block3{
        max-width: 369px;
        margin: auto;
        padding-top: 120px;
        padding-bottom: 160px;

        .form-title{
            margin-bottom: 30px;

            h2{
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                color: #000000;
                margin: 0px;
            }
        }

        .form-subtitle{
            margin-bottom: 25px;

            p{
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #666666;
                margin: 0px;
            }
        }

        .form-container{
            .css-11lq3yg-MuiGrid-root{
                flex-direction: column;
            }

            .form-step-title{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #222222;
                margin-bottom: 8px;
            }

            .input-column{
                .MuiSelect-select{
                    background: #FAFAFA;
                    border: 1px solid #E6E6E6;
                    box-sizing: border-box;
                    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 10%);
                    border-radius: 5px;
                    height: 40px;

                    em{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                    }
                }

                .MuiFormControl-root{
                    margin-bottom: 25px;
                }

                .MuiTextField-root{
                    input{
                        background: #FAFAFA;
                        border: 1px solid #E6E6E6;
                        box-sizing: border-box;
                        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        height: 40px;
                    }

                    label{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                    }
                }
            }

            .last-column .MuiTextField-root:last-child{
                background: #FAFAFA;
                border: 1px solid #E6E6E6;
                box-sizing: border-box;
                box-shadow: inset 0px 1px 2px rgb(0 0 0 / 10%);
                border-radius: 5px;
            }

            .checkbox-container{
                
                .formcontrollabel-container{
                    .MuiFormControlLabel-root{
                        margin-left: 0px;
                        margin-right: 0px;
                    }
    
                    .first-checkbox{
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        flex-wrap: nowrap;

                        .MuiTypography-root{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #666666;
                            margin-top: -10px;
        
                            a{
                                font-family: EncodeSansMedium;
                                font-weight: 500;
                                color: #000000;
                            }
        
                        }
                        .MuiSvgIcon-root{
                            color: #E6E6E6;
                            padding-bottom: 12.5px;
                        }
                    }

                    .MuiCheckbox-root{
                        padding-left: 0px;
                    }
                }
    
                .second-checkbox-container{
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    
                    .second-checkbox{
                        .MuiTypography-root{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #666666;
        
                            a{
                                font-family: EncodeSansMedium;
                                font-weight: 500;
                                color: #000000;
                            }
        
                        }
                        .MuiSvgIcon-root{
                            color: #E6E6E6;
                            padding-bottom: 12.5px;
                        }
                    }

                    .MuiCheckbox-root{
                        padding-left: 0px;
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
    
                    label{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                        padding-top: 2px;
                    }
                }
            }

            .button-container{
                margin-top: 25px;

                button{
                    max-width: 369px;
                    width: 100%;
                    height: 40px;
                    background: #000000;
                    border-radius: 5px;
                    text-transform: none;
                    font-family: EncodeSansSemiBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
        
        .campo-requerido{
            margin-top: 25px;
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #666666;
                margin: 0px;
            }
        }
    }
}
