@media(max-width: 991px){
    .distributor{
        .block1{
            
            .absolute-container{
                top: 134px;
    
                .header-text{
                    margin-bottom: 50px;
            
                    h1{
                        font-size: 48px;
                        line-height: 60px;
                    }
                }
    
                .absolute-blocks{
                    .appert-flow-container{
                        .appert-flow-content{
                            .appert-flow-isotipo-container{
                                .appert-flow-isotipo{
                                    object-fit: contain;
                                }
                            }
    
                            .appert-flow-text{
                                width: 16.7em;
                            }
            
                        }
        
                    }
        
                    .labnotes-isotipo-container{
                        .labnotes-isotipo-content{
                            .labnotes-isotipo-img-container{
                                .labnotes-isotipo{
                                    object-fit: contain;
                                }
                            }
    
                            .labnotes-isotipo-text{
                                width: 16.7em;
                            }
            
                        }
        
                    }
                }
    
            }

            .block1-text-bottom{
                padding-bottom: 75px;
                padding-left: 29px;
                padding-right: 29px;
            }
        }
    
        .block2{
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 75px;
            background-color: #ffffff;

            .block2-content{
    
                .block2-images-container{
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 100%;

                    .block2-images-block{
                        max-height: 600px !important;
                    }
                }

                .block2-text-bottom{
                    h4{
                        margin-top: 0px;
                    }
                }
            }
        }
    
        .block3{
            padding-left: 29px;
            padding-right: 29px;
        }
    }
}



@media(max-width: 767px){
    .distributor{
        .block1{
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 0px;
            padding-top: 100px;
            
            .absolute-container{
                position: unset;
                padding-top: 45px;
                padding-left: 23px;
                padding-right: 23px;
                padding-bottom: 100px;
    
                .header-text{
                    margin-bottom: 100px;
    
                    .header-logo{
                        display: none;
                    }
            
                    h1{
                        font-size: 32px;
                        line-height: 40px;
                        margin-bottom: 30px;
                    }
                }

                .distributor-container{
                    flex-wrap: nowrap;
                    flex-direction: column;
                    align-items: center;
                }
    
            }

            .block1-text-bottom{
                padding-top: 0px;
                padding-bottom: 50px;
                padding-left: 23px;
                padding-right: 23px;

                h4{
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
    
        .block2{
            padding-top: 100px;
            padding-bottom: 0px;
            padding-left: 0px;
            padding-right: 0px;
    
            .block2-content{
                padding-top: 0px;
    
                .block2-image-text-container{
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    border-radius: 0px;

                    .block2-image-container{
                        width: 100%;
                        max-width: none;
                        height: 300px;
                        border-radius: 0px;
                    }

                    .block2-text-container{
                        width: 88%;
                        padding-left: 23px;
                        padding-right: 23px;

                        p{
                            max-width: none;
                        }
                    }
                }
    
                .block2-text-bottom{
                    padding-top: 0px;
                    padding-bottom: 100px;
                    padding-left: 23px;
                    padding-right: 23px;
    
                    h4{
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
            }
        }
    
        .block3{
            padding-left: 23px;
            padding-right: 23px;
            
        }
    }
}

@media(max-width: 575px){
    .distributor{
        .block1{
            
            .absolute-container{

                .distributor-container{
                    align-items: stretch;
                    
                    .distributor-content{
                        width: calc(100% - 23px);
                        padding: 25px 15px;
                        margin-left: 0px;
                        margin-right: 0px;

                        p{
                            font-size: 15px;
                        }
                    }
                }
    
            }
        }
    }
}