.training{
    .block1{
        padding-left: 80px;
        padding-right: 80px;
        position: relative;
        z-index: 2;

        .image1-container{
            width: 100%;
            height: 808px;
            position: relative;

            .block1-images-filter{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                background-image: linear-gradient(#ffffff, #ffffff00);
            }

            .image1{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        
        .absolute-container{
            position: absolute;
            top: 148px;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: inherit;
            padding-right: inherit;

            .header-text{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                margin-bottom: 144px;
        
                h1{
                    font-family: EncodeSansMedium;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 64px;
                    line-height: 80px;
                    text-align: center;
                    color: #000000;
                }
            }

        }

        .block1-text-bottom{
            padding-top: 100px;
            padding-bottom: 150px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            h4{
                max-width: 910px;
                width: 100%;
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                color: #000000;
                margin-top: 0px;
            }

            .solicitar-button{
                width: 215px;
                height: 53px;
                background: #0000FF;
                border-radius: unset;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                text-transform: initial;
            }
        }
    }

    .block2{
        padding-left: 80px;
        padding-right: 80px;
        background-color: #F4F4F4;
        padding-top: 150px;
        padding-bottom: 150px;

        .block2-content{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: flex-start;
            
            .block2-text{
                max-width: 465px;
                margin-right: 6.3%;
                padding-left: 10px;

                h4{
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 50px;
                    color: #000000;
                    margin-top: 0px;
                    margin-bottom: 30px;
                }

                p{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #666666;

                    span{
                        font-family: EncodeSansBold;
                        font-weight: 700;
                    }
                }
            }

            .block2-image-container{
                max-width: 952px;
                width: 1000px;
                height: 1000px;
                max-height: 547px;
                border-radius: 20px 0px 0px 20px;
                margin-right: -80px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 20px 0px 0px 20px;
                }
            }
        }
    }

    .block3{
        padding-left: 80px;
        padding-right: 80px;
        background-color: #ffffff;
        padding-top: 150px;

        .block3-content{
            .block3-images-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                width: 98%;
                margin: auto;
                padding-left: 5px;
                padding-right: 5px;
                position: relative;

                .block3-first-text-block{
                    max-width: 465px;

                    p{
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                .block3-text-block{
                    max-width: 388px;

                    p{
                        font-size: 22px;
                        line-height: 28px;
                    }
                }

                .block3-text-block, .block3-first-text-block{
                    position: absolute;
                    width: 85.8%;
                    height: 85.8%;
                    padding: 7.1%;

                    h3{
                        font-family: EncodeSansMedium;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 40px;
                        line-height: 50px;
                        color: #FFFFFF;
                        margin-top: 0px;
                        margin-bottom: 30px;
                    }

                    p{
                        font-style: normal;
                        font-weight: 400;
                        color: #EEEEEE;
                        margin: 0px;
                    }
                    
                }

                .block3-first-images-block{
                    height: auto;
                    width: 100%;
                    max-width: 1137px;
                    display: flex;
                    position: relative;
                    border-radius: 20px;
                    overflow: hidden;

                    .block3-images-filter{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.7);
                    }
    
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .block3-images-second-block-container{
                    width: 100%;
                    max-width: 1137px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;

                    .block3-second-block{

                    }

                    .block3-images-block{
                        height: auto;
                        border-radius: 20px;
                        width: 100%;
                        max-width: 548px;
                        display: flex;
                        position: relative;
                        border-radius: 20px;
                        overflow: hidden;
                        height: 667px;
                        max-height: 665px;

                        .block3-images-filter{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.7);
                        }
        
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .block3-images-block:first-child{
                        margin-right: 40px;
                    }
                }
    
            }

            .block3-text-bottom{
                padding-bottom: 150px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;

                h4{
                    max-width: 850px;
                    width: 100%;
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 50px;
                    text-align: center;
                    color: #000000;
                }

                .solicitar-button{
                    width: 215px;
                    height: 53px;
                    background: #0000FF;
                    border-radius: unset;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    text-transform: initial;
                }
            }

            .divider{
                flex-shrink: 0;
                border-width: 0;
                border-style: solid;
                border-color: #C4C4C4;
                border-bottom-width: 2px;
                max-width: 945px;
                margin: auto;
            }
        }
    }

    .block4{
        max-width: 369px;
        margin: auto;
        padding-top: 120px;
        padding-bottom: 160px;

        .form-title{
            margin-bottom: 30px;

            h2{
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                color: #000000;
                margin: 0px;
            }
        }

        .form-subtitle{
            margin-bottom: 25px;

            p{
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #666666;
                margin: 0px;
            }
        }

        .form-container{
            .css-11lq3yg-MuiGrid-root{
                flex-direction: column;
            }

            .form-step-title{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #222222;
                margin-bottom: 8px;
            }

            .input-column{
                .MuiSelect-select{
                    background: #FAFAFA;
                    border: 1px solid #E6E6E6;
                    box-sizing: border-box;
                    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 10%);
                    border-radius: 5px;
                    height: 40px;

                    em{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                    }
                }

                .MuiFormControl-root{
                    margin-bottom: 25px;
                }

                .MuiTextField-root{
                    input{
                        background: #FAFAFA;
                        border: 1px solid #E6E6E6;
                        box-sizing: border-box;
                        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        height: 40px;
                    }

                    label{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                    }
                }
            }

            .checkbox-container{
                
                .formcontrollabel-container{
                    .MuiFormControlLabel-root{
                        margin-left: 0px;
                        margin-right: 0px;
                    }
    
                    .first-checkbox{
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        flex-wrap: nowrap;

                        .MuiTypography-root{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #666666;
                            margin-top: -10px;
        
                            a{
                                font-family: EncodeSansMedium;
                                font-weight: 500;
                                color: #000000;
                            }
        
                        }
                        .MuiSvgIcon-root{
                            color: #E6E6E6;
                            padding-bottom: 12.5px;
                        }
                    }

                    .MuiCheckbox-root{
                        padding-left: 0px;
                    }
                }
    
                .second-checkbox-container{
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    
                    .second-checkbox{
                        .MuiTypography-root{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #666666;
        
                            a{
                                font-family: EncodeSansMedium;
                                font-weight: 500;
                                color: #000000;
                            }
        
                        }
                        .MuiSvgIcon-root{
                            color: #E6E6E6;
                            padding-bottom: 12.5px;
                        }
                    }

                    .MuiCheckbox-root{
                        padding-left: 0px;
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
    
                    label{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                        padding-top: 2px;
                    }
                }
            }

            .button-container{
                margin-top: 25px;

                button{
                    max-width: 369px;
                    width: 100%;
                    height: 40px;
                    background: #000000;
                    border-radius: 5px;
                    text-transform: none;
                    font-family: EncodeSansSemiBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
        
        .campo-requerido{
            margin-top: 25px;
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #666666;
                margin: 0px;
            }
        }

        .training-form-flex{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }
}