@media(max-width: 1152px){
    .support{
        .block2{
            .block2-container{
                justify-content: center;
            }
        }
    }
}

@media(max-width: 991px){
    .support{
        .block1{
            .block1-container{
                .block1-title{
                    h1{
                        font-size: 48px;
                        line-height: 60px;
                    }
                }
                .block1-text{
                    p{
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
        }

        .block2{
            padding-left: 15.5px;
            padding-right: 15.5px;
        }

        .block3{
            .block3-text-bottom{
                padding-bottom: 75px;
                padding-left: 29px;
                padding-right: 29px;
            }
        }
    }
}

@media(max-width: 767px){
    .support{
        .block1{
            padding-top: 100px;
            padding-bottom: 50px;
            padding-left: 23px;
            padding-right: 23px;
    
            .block1-container{
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                flex-wrap: nowrap;
    
                .block1-title{
                    margin-right: 0px;
                    margin-bottom: 30px;
                }
            }
        }

        .block2{
            padding-top: 50px;
            padding-bottom: 40px;
        }

        .block3{
            .block3-text-bottom{
                padding-top: 20px;
                padding-bottom: 50px;
                padding-left: 23px;
                padding-right: 23px;

                h4{
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
    }
}

@media(max-width: 575px){
    .support{
        
    }
}