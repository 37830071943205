.demo-request{
    min-height: 100vh;
    
    .block1{
        max-width: 369px;
        margin: auto;
        padding-top: 120px;
        padding-bottom: 160px;

        .form-title{
            margin-bottom: 30px;

            h1{
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                color: #000000;
                margin: 0px;
            }
        }

        .form-subtitle{
            margin-bottom: 25px;

            p{
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #666666;
                margin: 0px;
            }
        }

        .form-container{
            .form-step-title{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #222222;
                margin-bottom: 8px;
            }

            .input-column{
                .MuiSelect-select{
                    background: #FAFAFA;
                    border: 1px solid #E6E6E6;
                    box-sizing: border-box;
                    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 10%);
                    border-radius: 5px;
                    height: 40px;

                    em{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                    }
                }

                .MuiFormControl-root{
                    margin-bottom: 25px;
                }

                .MuiTextField-root{
                    input{
                        background: #FAFAFA;
                        border: 1px solid #E6E6E6;
                        box-sizing: border-box;
                        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        height: 40px;
                    }

                    label{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                    }
                }
            }

            .checkbox-container{
                
                .formcontrollabel-container{
                    .MuiFormControlLabel-root{
                        margin-left: 0px;
                        margin-right: 0px;
                    }
    
                    .first-checkbox{
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        flex-wrap: nowrap;

                        .MuiTypography-root{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #666666;
                            margin-top: -10px;
        
                            a{
                                font-family: EncodeSansMedium;
                                font-weight: 500;
                                color: #000000;
                            }
        
                        }
                        .MuiSvgIcon-root{
                            color: #E6E6E6;
                            padding-bottom: 12.5px;
                        }
                    }

                    .MuiCheckbox-root{
                        padding-left: 0px;
                    }
                }
    
                .second-checkbox-container{
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    
                    .second-checkbox{
                        .MuiTypography-root{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #666666;
        
                            a{
                                font-family: EncodeSansMedium;
                                font-weight: 500;
                                color: #000000;
                            }
        
                        }
                        .MuiSvgIcon-root{
                            color: #E6E6E6;
                            padding-bottom: 12.5px;
                        }
                    }

                    .MuiCheckbox-root{
                        padding-left: 0px;
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }
    
                    label{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #666666;
                        padding-top: 2px;
                    }
                }
            }

            .button-container{
                margin-top: 25px;

                button{
                    max-width: 369px;
                    width: 100%;
                    height: 40px;
                    background: #000000;
                    border-radius: 5px;
                    text-transform: none;
                    font-family: EncodeSansSemiBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
        
        .campo-requerido{
            margin-top: 25px;
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #666666;
                margin: 0px;
            }
        }
    }
}