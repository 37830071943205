@media(max-width: 991px){
    .support-content{
        .block1{
            .block1-container{
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: flex-start;

                .block1-title{
                    h1{
                        font-size: 48px;
                        line-height: 60px;
                    }
                    h2{
                        font-size: 32px;
                        line-height: 36px;
                    }
                }
                .block1-text{
                    p{
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
        }

        .support-dinamic-content{
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;

            .block2{

            }

            .block3{
                max-width: 100%;
                width: 100%;
                margin-top: 50px;

                img{
                    max-width: 100%;
                }

                .videos-container{
                    .videos-content{
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-items: center;

                        .videos-title{
                            max-width: 465px;
                            margin-bottom: 10px;
                            
                            h5{
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        .block4{
            .block4-text-bottom{
                padding-bottom: 75px;
                padding-left: 29px;
                padding-right: 29px;
            }
        }
    }
}

@media(max-width: 767px){
    .support-content{
        .block1{
            padding-top: 100px;
            padding-bottom: 50px;
            padding-left: 23px;
            padding-right: 23px;
    
            .block1-container{
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                flex-wrap: nowrap;
    
                .block1-title{
                    margin-right: 0px;
                    margin-bottom: 30px;
                }
            }
        }

        .support-dinamic-content{
            padding-left: 23px;
            padding-right: 23px;

            .block3{
                .videos-container{
                    .videos-content{
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-items: center;

                        .videos-title{
                            max-width: 465px;
                            margin-bottom: 10px;
                            
                            h5{
                                text-align: center;
                            }
                        }
                    }
                }

                
            }
        }
        
        .block4{
            .block4-text-bottom{
                padding-top: 20px;
                padding-bottom: 50px;
                padding-left: 23px;
                padding-right: 23px;

                h4{
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
    }
}

@media(max-width: 575px){
    .support-content{
        .support-dinamic-content{
            .block3{
                .tutoriales-container{
                    .tutoriales-content{
                        .tutoriales-bloques{
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }

                .descargas-container{
                    .descargas-content{
                        flex-wrap: wrap;
    
                        .descargas-img{
                            margin-bottom: 10px;
    
                            img{
                                
                            }
                        }
                        .descargas-text{
                            width: 90%;

                            h5{
                                
                            }
    
                            p{
                                
                            }
                        }
                        .descargas-link{
                            width: 10%;
                        }
                    }
                }

                .videos-container{
                    .videos-content{
                        
                        .videos-video{
                            .videos-video-item{
                                .item-video{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}