@media(max-width: 991px){
    .student{
        .block1{
            .image1-container{
                height: 590px;
            }
            
            .absolute-container{
                top: 134px;
    
                .header-text{
                    margin-bottom: 50px;
            
                    h1{
                        font-size: 48px;
                        line-height: 60px;
                    }
                }
    
                .absolute-blocks{
                    
                    .appert-flow-container{
        
                        .appert-flow-content{
        
                            .appert-flow-isotipo-container{
            
                                .appert-flow-isotipo{
                                    object-fit: contain;
                                }
                            }
    
                            .appert-flow-text{
                                width: 16.7em;
                            }
            
                        }
        
                    }
        
                    .labnotes-isotipo-container{
        
                        .labnotes-isotipo-content{
        
                            .labnotes-isotipo-img-container{
            
                                .labnotes-isotipo{
                                    object-fit: contain;
                                }
                            }
    
                            .labnotes-isotipo-text{
                                width: 16.7em;
                            }
            
                        }
        
                    }
                }
    
            }

            .block1-text-bottom{
                padding-bottom: 75px;
                padding-left: 29px;
                padding-right: 29px;
            }
        }
    
        .block2{
            padding-left: 29px;
            padding-right: 29px;
            padding-bottom: 75px;
            padding-top: 75px;

            .block2-content{
                
                .block2-text{
                    margin-right: 15px;
                    padding-left: 0px;
                    max-width: 50%;
                }

                .block2-image-container{
                    margin-right: -29px;
                }
            }
        }
    
        .block2{
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 72px;
            background-color: #ffffff;

            .block2-content{
    
                .block2-images-container{
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 100%;

                    .block2-images-block{
                        max-height: 600px !important;
                    }
                }

                .block2-text-bottom{
                    h4{
                        margin-top: 0px;
                    }
                }
            }
        }

        .block4{
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 75px;
            background-color: #ffffff;

            .block4-content{
    
                .block4-images-container{
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 100%;

                    .block4-images-block{
                        max-height: 600px !important;
                    }
                }

                .block4-text-bottom{
                    h4{
                        margin-top: 0px;
                    }
                }
            }
        }
    
        .block5{
            padding-left: 29px;
            padding-right: 29px;
        }
    }
}

@media(max-width: 767px){
    .student{
        .block1{
            padding-left: 0px;
            padding-right: 0px;

            .image1-container{
                position: absolute;
                z-index: -1;
                height: 70%;
            }
            
            .absolute-container{
                position: unset;
                padding-top: 45px;
                padding-left: 23px;
                padding-right: 23px;
                padding-bottom: 50px;
    
                .header-text{
                    margin-bottom: 100px;
    
                    .header-logo{
                        display: none;
                    }
            
                    h1{
                        font-size: 32px;
                        line-height: 40px;
                        margin-bottom: 30px;
                    }
                }
    
            }
        }
    
        .block2{
            padding-left: 23px;
            padding-right: 23px;
            padding-top: 0px;
            padding-bottom: 50px;
    
            .block2-content{
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                
                .block2-text{
                    margin-right: 0px;
                    padding-left: 0px;
                    max-width: 100%;
                    margin-bottom: 32px;
    
                    h4{
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
    
                .block2-image-container{
                    margin-right: 0px;
                    width: 100%;
                    border-radius: 20px;
                    height: auto;
                    
                    img{
                        border-radius: 20px;
                    }
                }
            }
        }

        .block3{
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 23px;
            padding-right: 23px;

            .block3-text-bottom{
                padding-bottom: 0px !important;
                
                h4{
                    font-size: 32px !important;
                    line-height: 40px !important;
                }
            }

        }

        .block4{
            padding-top: 50px;
            padding-bottom: 0px;
            padding-left: 0px;
            padding-right: 0px;
    
            .block4-content{
                padding-top: 0px;
    
                .block4-image-text-container{
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    border-radius: 0px;

                    .block4-image-container{
                        width: 100%;
                        max-width: none;
                        height: 300px;
                        border-radius: 0px;
                    }

                    .block4-text-container{
                        width: 88%;
                        padding-left: 23px;
                        padding-right: 23px;

                        p{
                            max-width: none;
                        }
                    }
                }
    
                .block4-text-bottom{
                    padding-top: 0px;
                    padding-bottom: 100px;
                    padding-left: 23px;
                    padding-right: 23px;
    
                    h4{
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
            }
        }
    
        .block5{
            padding-left: 23px;
            padding-right: 23px;
            
        }
    }
}

@media(max-width: 575px){
    .student{
        .block1{
            .image1-container{
                height: 295px !important;
            }

            .absolute-container{
                position: unset;
                height: calc(295px - 45px);
            }
        }
        
        .block2{
            padding-top: 50px;

            .block2-image-container{
                height: 236px !important;
            }
        }
    }
}