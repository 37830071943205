.homepage{
    .block1{
        padding-left: 80px;
        padding-right: 80px;
        position: relative;
        z-index: 2;

        .image1-container{
            width: 100%;
            height: 808px;

            .image1{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        
        .absolute-container{
            position: absolute;
            top: 148px;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: inherit;
            padding-right: inherit;

            .header-text{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                margin-bottom: 144px;

                .header-logo{
                    width: 60px;
                    height: 60px;
                    margin-bottom: 15px;
                }
        
                h1{
                    font-family: EncodeSansSemiBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 64px;
                    line-height: 80px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-bottom: 15px;
                    margin-top: 0px;
                }
        
                h3{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-bottom: 15px;
                    margin-top: 0px;
                    max-width: 747px;
                }
        
                .header-button{
                    padding: 15px 5px;
                    width: 215px;
                    height: 53px;
                    background: #FFFFFF;
                    border-radius: unset;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #0000FF;
                    text-transform: initial;
                }
            }

            .absolute-blocks{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                width: 100%;
                
                .appert-flow-container{
                    margin-right: 15px;
                    max-width: 465px;
                    width: 45.8%;
                    height: 416px;
                    background: #F5F5F5;
                    border: 1px solid rgba(128, 128, 128, 0.2);
                    box-sizing: border-box;
                    border-radius: 5px;
                    padding: 10px;
    
                    .Image4-container{
                        width: 100%;
                        height: 100px;
    
                        .Image4{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .appert-flow-text-container{
                        display: block;
                    }

                    .appert-flow-text-container-mobile{
                        display: none;
                        padding-bottom: 20px;

                        h2{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 30px;
                            color: #000000;
                            margin-top: 23px;
                            margin-bottom: 37px;
                        }
        
                        p{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #000000;
                            margin-top: 0px;
                            margin-bottom: 10px;

                            span{
                                font-family: EncodeSansBold;
                                font-weight: 700;
                            }
                        }
        
                        a{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #000AFF !important;
                        }
                    }
    
                    .appert-flow-content{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: flex-start;
                        justify-content: flex-start;
                        padding-top: 40px;
    
                        .appert-flow-isotipo-container{
                            width: 64px;
                            height: 64px;
                            filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
        
                            .appert-flow-isotipo{
                                width: 100%;
                                height: 100%;
                                object-position: center;
                            }
                        }

                        .appert-flow-text{
                            margin-left: 13px;
                            width: 18.1em;

                            h2{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 30px;
                                color: #000000;
                                margin-top: 23px;
                                margin-bottom: 37px;
                            }
            
                            p{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #000000;
                                margin-top: 0px;
                                margin-bottom: 10px;

                                span{
                                    font-family: EncodeSansBold;
                                    font-weight: 700;
                                }
                            }
            
                            a{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #000AFF !important;
                            }
                        }
        
                    }
    
                }
    
                .labnotes-isotipo-container{
                    max-width: 465px;
                    width: 45.8%;
                    height: 416px;
                    background: #F5F5F5;
                    border: 1px solid rgba(128, 128, 128, 0.2);
                    box-sizing: border-box;
                    border-radius: 5px;
                    padding: 10px;

                    .labnotes-isotipo-text-container{
                        display: block;
                    }

                    .labnotes-isotipo-text-container-mobile{
                        display: none;
                        padding-bottom: 20px;

                        h2{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 30px;
                            color: #000000;
                            margin-top: 23px;
                            margin-bottom: 37px;
                        }
        
                        p{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #000000;
                            margin-top: 0px;
                            margin-bottom: 10px;

                            span{
                                font-family: EncodeSansBold;
                                font-weight: 700;
                            }
                        }
        
                        a{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #000AFF !important;
                        }
                    }
    
                    .Image5-container{
                        width: 100%;
                        height: 100px;
    
                        .Image5{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
    
                    .labnotes-isotipo-content{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: flex-start;
                        justify-content: flex-start;
                        padding-top: 40px;
    
                        .labnotes-isotipo-img-container{
                            width: 64px;
                            height: 64px;
                            filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
        
                            .labnotes-isotipo{
                                width: 100%;
                                height: 100%;
                                object-position: center;
                            }
                        }

                        .labnotes-isotipo-text{
                            margin-left: 13px;
                            width: 18.1em;

                            h2{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 30px;
                                color: #000000;
                                margin-top: 23px;
                                margin-bottom: 37px;
                            }
            
                            p{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #000000;
                                margin-top: 0px;
                                margin-bottom: 10px;

                                span{
                                    font-family: EncodeSansBold;
                                    font-weight: 700;
                                }
                            }
            
                            a{
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #000AFF !important;
                            }
                        }
        
                    }
    
                }
            }

        }
    }

    .block2{
        padding-left: 80px;
        padding-right: 80px;
        background-color: #EEEEEE;

        .block2-content{
            background-color: #ffffff;
            padding-top: 368px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: flex-end;
            
            .block2-text{
                max-width: 465px;
                margin-right: 6.3%;
                padding-left: 10px;

                h4{
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 50px;
                    color: #000000;
                    margin-top: 0px;
                    margin-bottom: 30px;
                }

                p{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #666666;

                    span{
                        font-family: EncodeSansBold;
                        font-weight: 700;
                    }
                }
            }

            .block2-image-container{
                max-width: 952px;
                width: 1000px;
                height: 1000px;
                max-height: 547px;
                border-radius: 20px 0px 0px 20px;
                margin-right: -80px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 20px 0px 0px 20px;
                }
            }
        }
    }

    .block3{
        padding-left: 80px;
        padding-right: 80px;
        background-color: #EEEEEE;

        .block3-content{
            background-color: #ffffff;
            padding-top: 200px;

            .block3-images-container{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                width: 98%;
                margin: auto;
                padding-left: 5px;
                padding-right: 5px;
                position: relative;

                .block3-first-text-block{
                    max-width: 465px;

                    h3{
                        max-width: 383px;
                    }

                    p{
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                .block3-text-block{
                    max-width: 388px;

                    p{
                        font-size: 22px;
                        line-height: 28px;
                    }
                }

                .block3-text-block, .block3-first-text-block{
                    position: absolute;
                    width: 85.8%;
                    height: 85.8%;
                    padding: 7.1%;

                    h3{
                        font-family: EncodeSansBold;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 40px;
                        line-height: 50px;
                        color: #FFFFFF;
                        margin-top: 0px;
                        margin-bottom: 30px;
                    }

                    p{
                        font-style: normal;
                        font-weight: 400;
                        color: #EEEEEE;
                        margin: 0px;
                    }
                }

                .block3-first-images-block{
                    height: auto;
                    border-radius: 20px;
                    width: 100%;
                    margin-bottom: 40px;
                    max-width: 1137px;
                    display: flex;
                    position: relative;
                    border-radius: 20px;
                    overflow: hidden;

                    .block3-images-filter{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.7);
                    }
    
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .block3-images-second-block-container{
                    width: 100%;
                    max-width: 1137px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;

                    .block3-second-block{

                    }

                    .block3-images-block{
                        height: auto;
                        border-radius: 20px;
                        width: 100%;
                        max-width: 548px;
                        display: flex;
                        position: relative;
                        border-radius: 20px;
                        overflow: hidden;
                        height: 667px;
                        max-height: 665px;

                        .block3-images-filter{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0.7);
                        }
        
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .block3-images-block:first-child{
                        margin-right: 40px;
                    }
                }
    
            }

            .block3-text-bottom{
                padding-top: 150px;
                padding-bottom: 150px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;

                h4{
                    max-width: 753px;
                    width: 100%;
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 50px;
                    text-align: center;
                    color: #000000;
                }

                .solicitar-button{
                    width: 215px;
                    height: 53px;
                    background: #0000FF;
                    border-radius: unset;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    text-transform: initial;
                }
            }

            .divider{
                flex-shrink: 0;
                border-width: 0;
                border-style: solid;
                border-color: #C4C4C4;
                border-bottom-width: 2px;
                max-width: 945px;
                margin: auto;
            }
        }
    }

    .block4{
        padding-top: 150px;
        padding-left: 29px;
        padding-right: 29px;

        .productos{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 20px;
        }

        .blocks-container{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            .block-content{
                max-width: 1137px;
                height: auto;
                width: 95%;
                background: #F4F4F4;
                border-radius: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;
                margin-bottom: 40px;

                .image-container{
                    max-width: 465px;
                    width: 52%;
                    overflow: hidden;
                    border-radius: 20px 0px 0px 20px;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                @media(min-width: 1196px){
                    .block-content-texts{
                        padding-left: 50px !important;
                        padding-right: 50px !important;
                        padding-bottom: 25px !important;
                        padding-top: 25px !important;
                    }
                }

                .block-content-texts{
                    padding-left: 4.2vw;
                    padding-right: 4.2vw;
                    padding-bottom: 2.1vw;
                    padding-top: 2.1vw;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: flex-start;

                    .block-content-img-container{
                        width: 80px;
                        height: 80px;
                        filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));

                        img{
                            width: 100%;
                            height: 100%;
                            object-position: center;
                            object-fit: contain;
                        }
                    }

                    .block-texts{
                        margin-left: 20px;

                        h4{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 32px;
                            line-height: 40px;
                            color: #000000;
                            margin-top: 30px;
                            margin-bottom: 28px;
                        }

                        h5{
                            max-width: 412px;
                            width: 100%;
                            font-family: EncodeSansBold;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 50px;
                            color: #000000;
                            margin-top: 0px;
                            margin-bottom: 30px;
                        }

                        p{
                            max-width: 412px;
                            width: 100%;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 28px;
                            color: #000000;
                            margin-top: 0px;
                            margin-bottom: 0px;

                            span{
                                font-family: EncodeSansBold;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }

    .block5{
        margin-top: 150px;
        padding-left: 29px;
        padding-right: 29px;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        .block5-image-container{
            max-width: 1136px;
            width: 100%;
            height: auto;
            display: flex;
            max-height: 635px;
            overflow: hidden;
            position: relative;

            .block5-image-filter{
                width: 100%;
                height: 100%;
                background-color: #ffffffba;
                position: absolute;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .block5-text{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            padding-left: inherit;
            padding-right: inherit;

            h4{
                max-width: 575px;
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                color: #000000;
                margin-bottom: 80px;
            }
            
            .block5-button{
                width: 215px;
                height: 53px;
                background: #0000FF;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                border-radius: unset;
                text-transform: initial;
            }
        }
    }
}