.appertflow{
    .block1{
        padding-left: 80px;
        padding-right: 80px;
        position: relative;
        z-index: 2;

        .image1-container{
            width: 100%;
            height: 808px;

            .image1{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        
        .absolute-container{
            position: absolute;
            top: 148px;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            padding-left: inherit;
            padding-right: inherit;

            .header-text{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                margin-bottom: 144px;

                .header-logo{
                    max-width: 180px;
                    object-fit: cover;
                }
        
                h1{
                    font-family: EncodeSansSemiBold;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 64px;
                    line-height: 80px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-bottom: 15px;
                    margin-top: 0px;
                    max-width: 754px;
                }
        
                h3{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-bottom: 15px;
                    margin-top: 0px;
                    max-width: 747px;
                }
        
                .header-button{
                    padding: 15px 5px;
                    width: 215px;
                    height: 53px;
                    background: #FFFFFF;
                    border-radius: unset;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #0000FF;
                    text-transform: initial;
                }
            }

            .absolute-blocks{
                max-width: 760px;
                width: 760px;
                position: relative;
    
                img{
                    position: absolute;
                    left: 0;
                    top: -35px;
                    width: 100%;
                    right: 0;
                }
            }

        }
    }

    .block2{
        padding-top: 470px;
        padding-bottom: 75px;
        padding-left: 29px;
        padding-right: 29px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .solicitar-button{
            width: 215px;
            height: 53px;
            background: #0000FF;
            border-radius: unset;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            text-transform: initial;
            margin-top: 50px;
            margin-bottom: 100px;
        }

        .block2-flex-container{
            max-width: 945px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 5px;
            margin: auto;

            .block2-flex-div:first-child{
                p{
                    font-weight: 700;
                    font-family: EncodeSansBold;
                }
            }
            .block2-flex-div{
                width: 100%;
                max-width: 465px;

                p{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                    color: #000000;
                    margin: 0px;
                }
            }
        }

        .block2-flex-three-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: space-between;
            max-width: 1137px;

            .block2-flex-div{
                max-width: 369px;
                width: 33%;
                border-top: 5px solid #000000;

                p{
                    font-family: EncodeSansRegular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                    color: #000000;

                    span{
                        font-family: EncodeSansSemiBold;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .block3{
        padding-top: 75px;
        padding-bottom: 150px;
        padding-left: 29px;
        padding-right: 29px;

        .block3-flex-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            max-width: 1137px;
            margin: auto;

            .block3-flex-img{
                width: 62%;

                .block3-img-1{
                    width: 83%;
                }
                .block3-img-2{
                    width: 65%;
                    object-position: -8px;
                }
            }
            .block3-flex-text{
                width: 38%;
            }
            .block3-flex-divs:first-child{
                margin-right: 15px;
            }
        }

        .block3-only-text{
            max-width: 1137px;
            margin: auto;
        }
        
        .block3-flex-divs{
            
            img{
                width: 100%;
                object-fit: cover;
            }

            h5{
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                color: #000000;
            }

            p{
                font-family: EncodeSansRegular;
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 28px;
                color: #000000;
            }
        }

        .block3-text-bottom-padding-120{
            padding-top: 120px;
            padding-bottom: 120px;
        }

        .block3-text-bottom-padding-60{
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .block3-text-bottom{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            h4{
                max-width: 865px;
                width: 100%;
                font-family: EncodeSansBold;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                color: #000000;
            }

            .solicitar-button{
                width: 215px;
                height: 53px;
                background: #0000FF;
                border-radius: unset;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                text-transform: initial;
            }
        }
    }

    .block4{
        padding-left: 80px;
        padding-right: 80px;
        background-color: #ffffff;
        padding-top: 0px;

        .block4-content{
            .block4-text-bottom{
                padding-bottom: 150px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;

                h4{
                    max-width: 865px;
                    width: 100%;
                    font-family: EncodeSansBold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 50px;
                    text-align: center;
                    color: #000000;
                }

                .solicitar-button{
                    width: 215px;
                    height: 53px;
                    background: #0000FF;
                    border-radius: unset;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    text-transform: initial;
                }
            }

            .divider{
                flex-shrink: 0;
                border-width: 0;
                border-style: solid;
                border-color: #C4C4C4;
                border-bottom-width: 2px;
                max-width: 945px;
                margin: auto;
            }
        }
    }
}